var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-table-container px-0 mx-0"},[_c('b-table',_vm._g({staticClass:"table-list",attrs:{"responsive":"","fixed":"","no-local-sorting":"","id":"order-table","items":_vm.orders,"fields":_vm.fields,"sort-by":_vm.camelCase(_vm.queryParams.sortBy),"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(orderDate)",fn:function(data){return [_c('div',{staticClass:"right-light-border"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_vm._v(" "+_vm._s(data.label)+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])])]}},{key:"head(createdBy)",fn:function(data){return [_c('div',{staticClass:"content"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_vm._v(" "+_vm._s(data.label)+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('LastFirst')))])])])])]}},{key:"cell(orderNumber)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('div',{on:{"click":function($event){return _vm.setSelectedTotal(data.item.total)}}},[_c('custom-router-link',{staticClass:"record-link text-primary mb-1",attrs:{"to":{
                            name:
                                data.item.orderTypeName === 'Direct Bulk Orders'
                                    ? 'BulkOrderDetails'
                                    : 'OrderDetails',
                            params:
                                data.item.orderTypeName === 'Direct Bulk Orders'
                                    ? {bulkOrderId: data.item.orderHeaderId.toString()}
                                    : {orderId: data.item.orderNumber.toString()},
                        }}},[_vm._v(" "+_vm._s(data.item.orderNumber)+" ")])],1)])]}}])},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }